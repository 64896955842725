import { IGetMarketingMetadataV1Response } from '@interface/marketing/getMarketingMetadataV1';

export const DEFAULT_META: Record<string, IGetMarketingMetadataV1Response> = {
  scg: {
    marketingSeo: {
      title: '서울도시가스',
      description: '서울도시가스채용',
      keywordList: ['서울도시가스', '서울도시가스채용', '서울도시가스채용 공고'],
      imageUrl: null,
    },
    marketingSns: {
      title: '서울도시가스',
      description: '서울도시가스채용',
      imageUrl: null,
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '',
    companyName: '서울도시가스',
  },
  kpgroup: {
    marketingSeo: {
      title: '한국석유공업',
      description: '한국석유공업채용',
      keywordList: ['한국석유공업', '한국석유공업채용', '한국석유공업채용 공고'],
      imageUrl: null,
    },
    marketingSns: {
      title: '한국석유공업',
      description: '한국석유공업채용',
      imageUrl: null,
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '827bd87dd9457e8510481a4f8411f138645e7b5a',
    companyName: '한국석유공업',
  },
  bcwp: {
    marketingSeo: {
      title: '비씨월드제약',
      description: '비씨월드제약채용',
      keywordList: ['비씨월드제약', '비씨월드제약채용', '비씨월드제약채용 공고'],
      imageUrl: null,
    },
    marketingSns: {
      title: '비씨월드제약',
      description: '비씨월드제약채용',
      imageUrl: null,
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '',
    companyName: '비씨월드제약',
  },
  fkc: {
    marketingSeo: {
      title: '한국화낙 채용',
      description: '미래 사회의 핵심가치를 만드는 한국화낙의 여정에 함께 해주세요.',
      keywordList: [
        '한국화낙',
        '한국화낙채용',
        '한국화낙 채용',
        '한국화낙 신입사원',
        '한국화낙 면접',
        '한국화낙 신입',
        '한국화낙 공채',
        '신입사원',
        '화낙',
      ],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/12/06/e922b352-84f2-46af-8bbd-e9214b4080ca.png',
    },
    marketingSns: {
      title: '',
      description: '',
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/11/21/453747a8-512d-4fc9-a4c2-5659d36ba8d2.png',
    },
    googleSearchTagContent: 'KUgLzTILvzbWTrZHN6cMwlyd6OUUmjtxXmbuJXAdSLs',
    naverSearchTagContent: '8b9b9c7878258c81084eb4912fc61caef6dbe937',
    companyName: '한국화낙',
  },
  'hyundaisteel-pipe': {
    marketingSeo: {
      title: '현대스틸파이프 채용',
      description:
        '글로벌 강관시장을 선도해온 현대스틸파이프에서\r\n새 역사를 함께 만들어 나아갈 우수인재를 모집합니다.',
      keywordList: [
        '현대스틸파이프',
        '현대스틸',
        '현대스틸파이프 채용',
        '현대스틸 채용',
        '현대 스틸 파이프',
        '현대스틸 파이프',
        '파이프',
        '울산',
      ],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/12/01/cd088474-c8b1-4371-a4ea-7c3c3d5f64f1.png',
    },
    marketingSns: {
      title: '현대스틸파이프 채용',
      description: '글로벌 강관시장을 선도해온 현대스틸파이프에서 새 역사를 함께 만들어 나아갈 우수인재를 모집합니다.',
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/12/01/11b3e432-2108-4e95-bebd-ca908a046e94.jpg',
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '',
    companyName: '현대스틸파이프',
  },
  daelimuc: {
    marketingSeo: {
      title: '대림대학교 채용',
      description:
        '학생이 중심이 되는 한국 최고의 특성화 전문대학교를 학생 여러분들과 교직원들이 함께 만들어 갑니다.\r\n',
      keywordList: ['대림대학교', '대림대학교 채용'],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/12/11/1211d293-a0c7-42bd-bb05-26835a3d7474.png',
    },
    marketingSns: {
      title: '대림대학교 채용',
      description: '',
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2023/12/11/57a0f887-348d-4d7b-acf7-d7c7b59a5384.jpg',
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '',
    companyName: '대림대학교',
  },
  im: {
    marketingSeo: {
      title: 'iM뱅크 채용',
      description:
        'iM뱅크는 금융 본연의 역할을 충실히 수행, 기업-고객-직원-사회환경 전체에 기여합니다.\r\n시대적 흐름과 참된 경영으로 ‘지역을 넘어 더불어 사는 풍요로운 세상을 만듭니다.',
      keywordList: [
        'iM뱅크 채용',
        '아이엠뱅크 채용',
        'iM뱅크 신입사원 채용',
        'iM뱅크 신입행원 채용',
        '아이엠뱅크 신입사원 채용',
        '아이엠뱅크 신입행원 채용',
        'DGB대구은행 신입 채용',
        '대구은행 신입 채용',
        'DGB대구은행 인턴 채용',
        '대구은행 채용',
        'DGB대구은행 채용',
        '대구은행 채용',
        'DGB대구은행 신입사원 채용',
        'DGB대구은행 신입행원 채용',
        '대구은행 신입사원 채용',
        '대구은행 신입행원 채용',
      ],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2024/09/03/3d8989d9-2352-44c2-b151-d12def126ee1.png',
    },
    marketingSns: {
      title: 'iM뱅크 채용',
      description: 'iM뱅크와 세상을 바꿀 인재를 모십니다.',
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2024/09/23/0be77191-c44a-4895-97af-c1d8591f4285.png',
    },
    googleSearchTagContent: 'yj6H-OrEIQH1jd-Iw3vV7rZSvweNQPzUbABYap69AIk',
    naverSearchTagContent: 'fc8157447bd26cb9e0109683da24f42ac7a4704a',
    companyName: 'iM뱅크',
  },
  ggm: {
    marketingSeo: {
      title: '광주글로벌모터스 채용',
      description:
        '광주글로벌모터스에서 무한한 가능성에 도전하며 상생의 새로운 가치를 창조해 나갈 주인공으로 함께하실 참신한 인재를 모십니다.',
      keywordList: [
        '광주글로벌모터스',
        '광주글로벌모터스 채용',
        'GGM',
        'GGM 채용',
        '광주글로벌모터스 인재풀',
        '광주글로벌모터스 경력사원',
        '광주글로벌모터스 신입사원',
        '광주글로벌모터스 일반직',
        '광주글로벌모터스 기술직',
      ],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2024/09/03/c9a6c649-5e44-42ed-8b23-be643dc78ecd.png',
    },
    marketingSns: {
      title: '광주글로벌모터스',
      description: '채용사이트',
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2024/05/17/4a239b4c-5329-47e1-b622-9424b8635fc7.png',
    },
    googleSearchTagContent: '',
    naverSearchTagContent: '85eab50fddfb3d10537676dfb4224ed1070e2f3d',
    companyName: '광주글로벌모터스',
  },
  gbattery: {
    marketingSeo: {
      title: '세방전지 채용',
      description: '세방전지, 세방전지 채용공고, 직무소개, 직무인터뷰, 회사소개, 복리후생, 지원서 작성 등',
      keywordList: ['세방전지 채용', '세방전지', '세방전지 직무소개', '세방전지 채용 홈페이지', '세방그룹'],
      imageUrl: 'https://infra1-static.recruiter.co.kr/builder/2024/10/16/ae07ae40-0023-4fdd-8340-d39e34bbe522.png',
    },
    marketingSns: {
      title: '세방전지 채용',
      description: '세방전지의 채용홈페이지입니다. 현재 채용중인 포지션을 확인해보세요!',
      imageUrl: null,
    },
    googleSearchTagContent: 'Fmr2B4jXuDWfLeAKGx9KzljFzeEGgHx653wAocXNG7M',
    naverSearchTagContent: '081d0f19835c87346707281a36491e6e36d33768',
    companyName: '세방전지 채용',
  },
};
